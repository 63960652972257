<!-- Footer template -->
<template>
  <v-footer dark padless app inset class="no-print">
    <v-card class="flex" flat tile color="grey" :elevation="10">
      <v-card-text class="py-2 white0--text text-center">
        {{ new Date().getFullYear() }} - <strong>Bitonic Systems Oy</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
@media print {
  .no-print {
    display: none;
  }
}
</style>
