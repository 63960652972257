<template>
  <nav class="no-print">
    <v-app-bar color="grey" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-tab @click="showMessages">
        Viestit

        <v-badge color="red" :content="messages" v-if="messages" />
      </v-tab>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left large>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"></v-icon></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text @click="logout">
        <v-icon right large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app class="grey" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          {{ user.userEmail }}
          <p class="white--text subheading mt-1 text-center">
            {{ user.email }}
          </p>
        </v-flex>
        <v-flex class="mt-4 mb-4">
          <v-divider color="white"></v-divider>
        </v-flex>
      </v-layout>
      <v-list class="text-left">
        <!--Home link-->
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Etusivulle</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/clothes-to-rental/add-clothes-rental-order">
            <v-list-item-icon>
              <v-icon>mdi-hanger</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tilaa vaatteet</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/clothes-to-rental/my-items-order-list">
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Oma tilauslista</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/clothes-to-rental/show-my-clothes-rental-orders">
            <v-list-item-icon>
              <v-icon>mdi-playlist-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä omat tilaukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item @click="showloadFile('Pikaohje.pdf')">
            <v-list-item-icon>
              <v-icon>mdi-help</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pikaohje</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item to="/clothes-to-rental/add-new-message-user">
            <v-list-item-icon>
              <v-icon>mdi-information-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Palaute / viesti</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-if="user.userRoles === 'admin' || user.userRoles === 'super-admin'"
        >
          <v-list-item to="/clothes-to-rental/add-new-message-admin">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Palaute käyttäjille</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-if="user.userRoles === 'admin' || user.userRoles === 'super-admin'"
        >
          <v-list-item
            to="/clothes-to-rental/show-all-new-clothes-rental-orders"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä uudet tilaukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group
          v-if="user.userRoles === 'admin' || user.userRoles === 'super-admin'"
        >
          <v-list-item to="/clothes-to-rental/show-all-clothes-rental-orders">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Näytä kaikki tilaukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group
          :value="false"
          prepend-icon="mdi-leak"
          v-if="user.userRoles === 'admin' || user.userRoles === 'super-admin'"
        >
          <template v-slot:activator>
            <v-list-item-title>Raportit</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in reports"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          prepend-icon="mdi-leak"
          class="blue"
          v-if="user.userRoles === 'admin' || user.userRoles === 'super-admin'"
        >
          <template v-slot:activator>
            <v-list-item-title>Hallinta</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in ClothesToRental"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- Drop-down -->

      <!-- Drop-down end -->
    </v-navigation-drawer>

    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
import Pikaohje from '@/services/ClothesToRentalReportsService.js';
import MessageService from '@/services/MessageService.js';
export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    messages: [],
    users: [
      ['Muuta salasana', 'mdi-account', '/clothes-to-rental/change-password'],
    ],
    ClothesToRental: [
      [
        'Lisää uusi osasto',
        '',
        '/clothes-to-rental/add-clothes-rental-department',
      ],
      [
        'Näytä osastot',
        '',
        '/clothes-to-rental/show-clothes-rental-departments',
      ],
      ['Lisää uusi tuote', '', '/clothes-to-rental/add-clothes-rental-product'],
      ['Näytä tuotteet', '', '/clothes-to-rental/show-clothes-rental-products'],
    ],
    reports: [
      [
        'Raportti ajalta',
        '',
        '/clothes-to-rental/clothes-to-rental-show-report-date-between',
      ],
      [
        'Raportti tilatus vs toimitetut',
        '',
        '/clothes-to-rental/clothes-to-rental-show-report-compare-amount-delivered',
      ],
    ],
  }),
  async created() {
    this.user = this.$store.state.user;
    const response = await MessageService.getAllMessagesUser({ state: 'Uusi' });
    this.messages = response.data.message.length;
  },

  methods: {
    showMessages() {
      this.$router.push({
        name: 'show-messages',
      });
    },
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
    async showloadFile(downloadFile) {
      try {
        const response = await Pikaohje.downloadMaterialbankFile({
          file: downloadFile,
        });

        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
@media print {
  .no-print {
    display: none;
  }
}
</style>
